import React, { Component } from 'react';
import InventoryCard from './InventoryCard';
import './InventoryGrid.css'

class InventoryGrid extends Component {
  
    constructor(props) {
        super(props);
      
        this.state = {
            gridItems: [
                <InventoryCard
                    imgsrc="placeholderimg.jfif"
                    carmodel="Placeholder Model"
                    status="available"
                    price="9999.99"
                    bodytype="SUV"
                    mileage="10,000"
                    inventory="#TDSU0000" 
                />
            ]
        }
    
        this.onAdd = this.onAdd.bind(this);
    }
    
    onAdd = () => {
        if (document.getElementById('img').value != '' && document.getElementById('model').value != '' && document.getElementById('price').value != '' && document.getElementById('body').value != '' && document.getElementById('mile').value != '' && document.getElementById('num').value != '') {
            this.setState(state => ({
                gridItems: [
                    ...state.gridItems, 
                    <InventoryCard
                        imgsrc={document.getElementById('img').value}
                        carmodel={document.getElementById('model').value}
                        status={document.getElementById('available').checked ? 'available' : 'unavailable'}
                        price={document.getElementById('price').value}
                        bodytype={document.getElementById('body').value}
                        mileage={document.getElementById('mile').value}
                        inventory={document.getElementById('num').value}
                    />
                ]
            }));
        }
    }
    
    render() {
        return (
            <div>
                <div className="grid-container">
                    {this.state.gridItems.map((element, index) => {
                        return (
                            <div key={index}>
                                {element}
                            </div>
                        );
                    })}
                </div>
                <input type="text" id="img" placeholder="Src" />
                <br />
                <input type="text" id="model" placeholder="Model" />
                <br />
                <input type="checkbox" id="available" />
                <label htmlFor="available">Available</label>
                <br />
                <input type="number" id="price" placeholder="Price" min="0" />
                <br />
                <input type="text" id="body" placeholder="Body" />
                <br />
                <input type="number" id="mile" placeholder="Mile" />
                <br />
                <input type="text" id="num" placeholder="No." />
                <br />
                <button className="add" onClick={this.onAdd}>Add</button>
            </div>
        )
    }
}

export default InventoryGrid;