import React from 'react';
import { GoTriangleRight } from 'react-icons/go';
import './InventoryCard.css';

function InventoryCard({imgsrc, carmodel, status, price, bodytype, mileage, inventory}) {

    return (
        <div className="car-card-container">
            <img src={imgsrc} alt="Image of a car" className="car-img" />
            <div className="car-title-container">
                <p className="car-model">{carmodel}</p>
                <div className={`availability ${status}`}>{status === 'available' ? 'Available' : 'Unavailable'}</div>
            </div>
            <div className="price-container">
                <p className="dealer-price">Dealer Price</p>
                <p className="price-of-car">${price}</p>
            </div>
            <div className="car-info">
                <div className="car-info-type">
                    <p className="car-info-heading">Body Type</p>
                    <p className="car-info-content">{bodytype}</p>
                </div>
                <div className="car-info-mile">
                    <p className="car-info-heading">Mileage (KM)</p>
                    <p className="car-info-content">{mileage}</p>
                </div>
                <div className="car-info-inventory">
                    <p className="car-info-heading">Inventory No.</p>
                    <p className="car-info-content">{inventory}</p>
                </div>
            </div>
            <div className="details-container">
                <button className="additional-details" type="button">
                    <p className="detail-text">Additional Details</p>
                    <GoTriangleRight className="detail-arrow" />
                </button>
            </div>
            
        </div>
    )
}

export default InventoryCard;