import React from 'react';
import './Navbar.css'

function Navbar(props) {
    return (
        <div className="navbar-container">
            <img className="navbar-logo" src="TDALogo.jpg" alt="TopDeal Auto Logo" />
            {/* <div className="flexbox" />
            <div className="items">
                <div className="item-container home">
                    Home
                </div>
                <div className="item-container inventory">
                    <p className="item-text">Inventory</p>
                </div>
                <div className="item-container about-us">
                    <p className="item-text">About Us</p>
                </div>
                <div className="item-container contact-us">
                    <p className="item-text">Contact Us</p>
                </div>
            </div> */}
        </div>
    )
}

export default Navbar;