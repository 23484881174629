import React, { Component } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './InventorySlider.css';

class InventorySlider extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            index: 0
        }

        this.previousCard = this.previousCard.bind(this);
        this.nextCard = this.nextCard.bind(this);

    }

    previousCard = () => {
        setTimeout(() => {
            if (this.state.index != 0) {
                this.setState(state => ({
                    index: state.index - 1
                }));
            } else {
                this.setState((state, props) => ({
                    index: props.slides.length - 1
                }));
            }
        }, 0);
    }

    nextCard = () => {
        setTimeout(() => {
            if (this.state.index < this.props.slides.length - 1) {
                this.setState(state => ({
                    index: state.index + 1
                }));
            } else {
                this.setState({
                    index: 0
                });
            }
        }, 0);
    }

    render() {
        return (
            <div className="slider-container">
                <div className="slider">
                    <button className="prev" onClick={this.previousCard}><IoIosArrowBack /></button>
                    <div className="slide-cards">
                        <div className="current-card left" id={this.state.index < this.props.slides.length - 1 ? this.state.index + 1 : 0}>
                            {this.props.slides[this.state.index < this.props.slides.length - 1 ? this.state.index + 1 : 0]}
                        </div>
                        <div className="current-card middle" id={this.state.index}>
                            {this.props.slides[this.state.index]}
                        </div>
                        <div className="current-card right" id={this.state.index > 0 ? this.state.index - 1 : this.props.slides.length - 1}>
                            {this.props.slides[this.state.index > 0 ? this.state.index - 1 : this.props.slides.length - 1]}
                        </div>
                    </div>
                    <button className="next" onClick={this.nextCard}><IoIosArrowForward /></button>
                </div>
            </div>
        )
    }
}

export default InventorySlider;