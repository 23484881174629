import React from 'react';
import InventoryGrid from './components/InventoryGrid';
import InventorySlider from './components/InventorySlider';
import InventoryCard from './components/InventoryCard';
import './App.css';
import Navbar from './components/Navbar';
import { FaCarSide } from 'react-icons/fa';

function App() {

  

  const slides = [
    (<InventoryCard
      imgsrc="placeholderimg.jfif"
      carmodel="Placeholder Model"
      status="available"
      price="9999.99"
      bodytype="SUV"
      mileage="10,000"
      inventory="#TDSU0000" 
    />),
    (<InventoryCard
      imgsrc="blackcar.jfif"
      carmodel="Black Car"
      status="available"
      price="9999.99"
      bodytype="SUV"
      mileage="10,000"
      inventory="#TDSU0001" 
    />),
    (<InventoryCard
      imgsrc="greycar.jpg"
      carmodel="Grey Car"
      status="available"
      price="9999.99"
      bodytype="SUV"
      mileage="10,000"
      inventory="#TDSU0002" 
    />),
    (<InventoryCard
      imgsrc="whitecar.jpg"
      carmodel="White Car"
      status="available"
      price="9999.99"
      bodytype="SUV"
      mileage="10,000"
      inventory="#TDSU0003" 
    />)
  ]

  return (
    <div className="app">
      {/* <InventoryGrid />
      <InventorySlider slides={slides} />*/}
      <Navbar />
      <img src="background.jpg" alt="Backsground Image" className="background" />
      <div className="main-div">
        <p className="welcome">Welcome to<br/>TopDeal Auto Group Ltd.</p>
        <p className="updating-soon">Updating Soon</p>
        <p className="info-text address">210 - 9321 Scott Rd<br/>Delta, BC V4C 6R8</p>
      </div>
      <div id="opaque-div">
        <p className="info-text"><strong>Ph:</strong> <br />604.498.1112</p>
        <p className="info-text"><strong>Email:</strong> <br />info@topdealauto.ca</p>
        <p className="info-text"><strong>Fax:</strong> <br />604.498.1402</p>
      </div>
    </div>
  )
}

export default App;